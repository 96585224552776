import { Options } from "vue-class-component";
import BaseFunction from "@/mixins/BaseFunction";
import ProcessTreeViewer from "./ProcessTreeViewer/ProcessTreeViewer.vue";
import DefaultNodeProps from "@/modules/flowable/common/process/DefaultNodeProps";

@Options({
    name: "flowable-process-design-viewer",
    components: {
        "process-tree-viewer": ProcessTreeViewer,
    },
    watch: {
        /*selectedNode:{
          deep: true,
          handler(node){
            console.log("更新")
            this.$refs["process-tree"].nodeDomUpdate(node)
          }
        }*/
    },
})
export default class ProcessDesignViewer extends BaseFunction {
    private scale = 100;
    private showConfig = false;

    public activated() {
        this.$store.dispatch("flowable/setDiagramMode", "viewer").then(() => {});
    }
    public unactivated() {
        this.$store.dispatch("flowable/setDiagramMode", "design").then(() => {});
    }

    get setup(): any {
        return this.$store.getters["flowable/design"];
    }
    set setup(val: any) {
        this.$store.dispatch("flowable/setDesign", val).then();
    }

    get selectedNode(): any {
        return this.$store.getters["flowable/selectedNode"];
    }
    set selectedNode(val: any) {
        this.$store.dispatch("flowable/setSelectedNode", val).then();
    }

    public validate() {
        return (this.$refs["process-tree-viewer"] as any).validateProcess();
    }
    public nodeSelected(node: any) {
        if (node.type === "APPROVAL") {
            node.props = Object.assign({}, DefaultNodeProps.APPROVAL_PROPS, node.props);
        } else if (node.type === "CC") {
            node.props = Object.assign({}, DefaultNodeProps.CC_PROPS, node.props);
        } else if (node.type === "ROOT") {
            node.props = Object.assign({}, DefaultNodeProps.ROOT_PROPS, node.props);
        } else if (node.type === "CONDITION") {
            node.props = Object.assign({}, DefaultNodeProps.CONDITION_PROPS, node.props);
        } else if (node.type === "DELAY") {
            node.props = Object.assign({}, DefaultNodeProps.DELAY_PROPS, node.props);
        } else if (node.type === "TRIGGER") {
            node.props = Object.assign({}, DefaultNodeProps.TRIGGER_PROPS, node.props);
        }
        // console.log("保存节点", this.selectedNode);
        // console.log("配置节点", node);
        this.showConfig = true;
    }
}
