import { resolveComponent as _resolveComponent, createVNode as _createVNode, vShow as _vShow, withDirectives as _withDirectives, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5450b0d3"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "content-layout" }
const _hoisted_2 = { class: "content" }
const _hoisted_3 = { class: "container-fluid" }
const _hoisted_4 = { class: "card card-flex card-outline card-themes" }
const _hoisted_5 = {
  class: "card-body table-responsive p-0",
  style: {"background-color":"#f5f6f6"}
}
const _hoisted_6 = {
  key: 0,
  class: "overlay hyper-text-themes animate__animated animate__fadeIn"
}

export function render(_ctx, _cache) {
  const _component_app_content_header = _resolveComponent("app-content-header")
  const _component_flowable_layout_header_workspace = _resolveComponent("flowable-layout-header-workspace")
  const _component_flowable_layout_approve_workspace = _resolveComponent("flowable-layout-approve-workspace")
  const _component_flowable_layout_logs_workspace = _resolveComponent("flowable-layout-logs-workspace")
  const _component_flowable_layout_process_design_viewer = _resolveComponent("flowable-layout-process-design-viewer")
  const _component_svg_icon = _resolveComponent("svg-icon")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_app_content_header),
    _createElementVNode("section", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_flowable_layout_header_workspace, {
            modelValue: _ctx.activeSelect,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => ((_ctx.activeSelect) = $event))
          }, null, 8, ["modelValue"]),
          _createElementVNode("div", _hoisted_5, [
            _withDirectives(_createVNode(_component_flowable_layout_approve_workspace, { ref: "approveWorkspace" }, null, 512), [
              [_vShow, _ctx.activeSelect === 'approveWorkspace']
            ]),
            _withDirectives(_createVNode(_component_flowable_layout_logs_workspace, { ref: "logsWorkspace" }, null, 512), [
              [_vShow, _ctx.activeSelect === 'logsWorkspace']
            ]),
            _withDirectives(_createVNode(_component_flowable_layout_process_design_viewer, { ref: "processWorkspace" }, null, 512), [
              [_vShow, _ctx.activeSelect === 'processDesignViewer']
            ])
          ]),
          (_ctx.isLoading)
            ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                _createVNode(_component_svg_icon, {
                  name: "ali-loading",
                  size: "xl",
                  class: "turn"
                })
              ]))
            : _createCommentVNode("", true)
        ])
      ])
    ])
  ]))
}