import { Options } from "vue-class-component";
import BaseFunction from "@/mixins/BaseFunction";
import FlowableLayoutHeaderWorkspace from "@/modules/flowable/workspace/header-workspace/header-workspace.vue";
import FlowableLayoutApproveWorkspace from "@/modules/flowable/workspace/approve-workspace/approve-workspace.vue";
import FlowableLayoutLogsWorkspace from "@/modules/flowable/workspace/logs-workspace/logs-workspace.vue";
import FlowableLayoutProcessDesignViewer from "@/modules/flowable/process-design-viewer/process-design-viewer.vue";
import API from "@/api/flowable/workspace/work";

@Options({
    name: "app-flowable-workspace-workspace",
    components: {
        "flowable-layout-header-workspace": FlowableLayoutHeaderWorkspace,
        "flowable-layout-approve-workspace": FlowableLayoutApproveWorkspace,
        "flowable-layout-logs-workspace": FlowableLayoutLogsWorkspace,
        "flowable-layout-process-design-viewer": FlowableLayoutProcessDesignViewer,
    },
})
export default class Work extends BaseFunction {
    private activeSelect = "approveWorkspace";
    private isLoading = false;
    private processInstanceId: any = "";
    private taskId: any = "";
    private form: any = null;
    private formData: any = {};
    private startUser: any = {};
    private currentNode: any = {};
    private processInfo: any = {};

    get setup() {
        return this.$store.getters["flowable/design"];
    }

    get forms() {
        return this.setup.formItems;
    }

    public activated() {
        this.processInstanceId = this.$route.query.processInstanceId;
        this.taskId = this.$route.query.taskId;
        this.processInfo = {
            processInstanceId: this.processInstanceId,
            taskId: this.taskId,
        };
        this.getProcessInfo();
    }

    public getProcessInfo() {
        this.isLoading = true;
        API.getProcessInstanceInfo(this.processInfo)
            .then((rsp: any) => {
                //console.log("流程处理：", rsp.data);

                const form = rsp.data.processTemplates;
                form.logo = JSON.parse(form.logo);
                form.settings = JSON.parse(form.settings);
                form.formItems = JSON.parse(form.formItems);
                form.process = JSON.parse(form.process);

                const formItems = this.$tools.flatFormItem(form.formItems);
                //console.log("formItems 1", formItems);

                const perms = rsp.data?.currentNode?.props?.formPerms || [];

                const map = new Map(perms.map((it: any) => [it.id, it.perm]));
                const removeIndices = [];
                for (let i = 0; i < formItems.length; i++) {
                    const formItem = formItems[i];
                    const perm = map.get(formItem.id);
                    if (perm === "E") {
                        formItem.props.readerMode = false;
                    } else if (perm === "R") {
                        formItem.props.readerMode = true;
                    } else if (perm === "H") {
                        removeIndices.push(i);
                    } else {
                        formItem.props.readerMode = true;
                    }
                }

                removeIndices.reverse().forEach((it) => formItems.splice(it, 1));
                //console.log("formItems 2", formItems);
                form.formItems = formItems;

                this.$store.dispatch("flowable/setDesign", form).then(() => {});
                this.$store.dispatch("flowable/setEndList", rsp.data.endList).then(() => {});
                this.$store.dispatch("flowable/setRunningList", rsp.data.runningList).then(() => {});
                this.$store.dispatch("flowable/setNoTakeList", rsp.data.noTakeList).then(() => {});
                this.$store.dispatch("flowable/setDetailVOList", rsp.data.detailVOList).then(() => {});
                this.$store.dispatch("flowable/setStartUser", rsp.data.startUser).then(() => {});
                this.$store.dispatch("flowable/setFormData", rsp.data.formData).then(() => {});
                this.$store.dispatch("flowable/setCurrentNode", rsp.data.currentNode).then(() => {});
                this.$store.dispatch("flowable/setProcessInfo", this.processInfo).then(() => {});
            })
            .finally(() => {
                this.isLoading = false;
            });
    }
}
