import { Options } from "vue-class-component";
import BaseFunction from "@/mixins/BaseFunction";
import FormRender from "../../setting/form-setting/FormRender/FormRender.vue";
import API from "@/api/flowable/workspace/work";

@Options({
    name: "flowable-workspace-approve-workspace",
    components: {
        FormRender,
    },
})
export default class ApproveWorkspace extends BaseFunction {
    private processInstanceId: any = "";
    private taskId: any = "";
    private approveForm: any = {
        multiAddUserInfo: { id: 47, name: "张丹" }, // 加签用户信息
        transferUserInfo: { id: 46, name: "何航杰" }, // 转办用户信息
        delegateUserInfo: { id: 45, name: "蒋玮洁" }, // 委派的人
        comments: "同意",
    };

    public activated() {
        this.processInstanceId = this.$route.query.processInstanceId;
        this.taskId = this.$route.query.taskId;
    }

    get setup() {
        return this.$store.getters["flowable/design"];
    }
    get formData() {
        return this.$store.getters["flowable/formData"];
    }
    get startUser() {
        return this.$store.getters["flowable/startUser"];
    }
    get processInfo() {
        return this.$store.getters["flowable/processInfo"];
    }
    get formItems() {
        return this.setup.formItems;
    }
    get templateName() {
        return this.setup.templateName;
    }
    get startUserName() {
        return this.startUser.title;
    }

    get approveInfo() {
        return {
            ...this.processInfo,
            ...this.approveForm,
            formData: this.formData,
        };
    }

    //同意
    public onAgree() {
        console.log("同意");
        API.agree(this.approveInfo).then((res: any) => {
            console.log("同意res", res);
        });
    }
    //驳回
    public onReject() {
        console.log("驳回");
        API.reject(this.approveInfo).then((res: any) => {
            console.log("驳回res", res);
        });
    }
    //委派
    public onDelegate() {
        console.log("委派");
        API.delegate(this.approveInfo).then((res: any) => {
            console.log("委派res", res);
        });
    }
    //转办
    public onAssignee() {
        console.log("转办");
        API.assignee(this.approveInfo).then((res: any) => {
            console.log("转办res", res);
        });
    }
    //撤销
    public onRevoke() {
        console.log("撤销");
        API.revoke(this.approveInfo).then((res: any) => {
            console.log("撤销res", res);
        });
    }
    //退回
    public onRollback() {
        console.log("退回");
        API.rollback(this.approveInfo).then((res: any) => {
            console.log("退回res", res);
        });
    }
    //加签
    public onAddMulti() {
        console.log("加签");
        API.addMulti(this.approveInfo).then((res: any) => {
            console.log("加签res", res);
        });
    }
    //减签
    public onDeleteMulti() {
        console.log("减签");
        API.deleteMulti(this.approveInfo).then((res: any) => {
            console.log("减签res", res);
        });
    }
    //评论
    public onComments() {
        console.log("评论");
        API.comments(this.approveInfo).then((res: any) => {
            console.log("评论res", res);
        });
    }
    //查到签上的人
    public onQueryMultiUsersInfo() {
        console.log("查到签上的人");
        API.queryMultiUsersInfo(this.approveInfo).then((res: any) => {
            console.log("查到签上的人res", res);
        });
    }
}
