import requests from "@/utils/axios";

export default {
    // 模块URL
    modulesUrl: "/flowable/workspace/work",
    // 获取流程详情
    getProcessInstanceInfo(query: any) {
        return requests.post(this.modulesUrl + "/getProcessInstanceInfo", query);
    },
    //上传图片
    uploadImage(query: any) {
        return requests.post(this.modulesUrl + "/uploadImage", query);
    },
    //视频图片
    uploadVideo(query: any) {
        return requests.post(this.modulesUrl + "/uploadVideo", query);
    },
    //上传附件
    uploadAttachment(query: any) {
        return requests.post(this.modulesUrl + "/uploadAttachment", query);
    },
    //同意
    agree(query: any) {
        return requests.post(this.modulesUrl + "/agree", query);
    },
    //驳回
    reject(query: any) {
        return requests.post(this.modulesUrl + "/reject", query);
    },
    //委派
    delegate(query: any) {
        return requests.post(this.modulesUrl + "/delegate", query);
    },
    //转办
    assignee(query: any) {
        return requests.post(this.modulesUrl + "/assignee", query);
    },
    //撤销
    revoke(query: any) {
        return requests.post(this.modulesUrl + "/revoke", query);
    },
    //退回
    rollback(query: any) {
        return requests.post(this.modulesUrl + "/rollback", query);
    },
    //加签
    addMulti(query: any) {
        return requests.post(this.modulesUrl + "/addMulti", query);
    },
    //减签
    deleteMulti(query: any) {
        return requests.post(this.modulesUrl + "/deleteMulti", query);
    },
    //评论
    comments(query: any) {
        return requests.post(this.modulesUrl + "/comments", query);
    },
    //查到签上的人
    queryMultiUsersInfo(query: any) {
        return requests.post(this.modulesUrl + "/queryMultiUsersInfo", query);
    },
    //催办
    urging(query: any) {
        return requests.post(this.modulesUrl + "/urging", query);
    },
};
