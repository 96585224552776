import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-a717af98"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "card-header text-center" }
const _hoisted_2 = { class: "card-header-button" }

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("button", {
        type: "button",
        class: _normalizeClass(["btn mr-2", _ctx.btnThemes('approveWorkspace')]),
        onClick: _cache[0] || (_cache[0] = $event => (_ctx.to('approveWorkspace')))
      }, "① 审批信息", 2),
      _createElementVNode("button", {
        type: "button",
        class: _normalizeClass(["btn mr-2", _ctx.btnThemes('logsWorkspace')]),
        onClick: _cache[1] || (_cache[1] = $event => (_ctx.to('logsWorkspace')))
      }, "② 审批日志", 2),
      _createElementVNode("button", {
        type: "button",
        class: _normalizeClass(["btn mr-2", _ctx.btnThemes('processDesignViewer')]),
        onClick: _cache[2] || (_cache[2] = $event => (_ctx.to('processDesignViewer')))
      }, "③ 流程图", 2)
    ])
  ]))
}