import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7d0bd5d8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "scale" }

export function render(_ctx, _cache) {
  const _component_el_button = _resolveComponent("el-button")
  const _component_process_tree_viewer = _resolveComponent("process-tree-viewer")
  const _component_el_main = _resolveComponent("el-main")

  return (_openBlock(), _createBlock(_component_el_main, { style: {"height":"100%","background-color":"#f5f6f6"} }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_el_button, {
          icon: "Plus",
          size: "default",
          onClick: _cache[0] || (_cache[0] = $event => (_ctx.scale += 10)),
          disabled: _ctx.scale >= 150,
          circle: ""
        }, null, 8, ["disabled"]),
        _createElementVNode("span", null, _toDisplayString(_ctx.scale) + "%", 1),
        _createVNode(_component_el_button, {
          icon: "Minus",
          size: "default",
          onClick: _cache[1] || (_cache[1] = $event => (_ctx.scale -= 10)),
          disabled: _ctx.scale <= 40,
          circle: ""
        }, null, 8, ["disabled"])
      ]),
      _createElementVNode("div", {
        class: "design",
        style: _normalizeStyle('transform: scale('+ _ctx.scale / 100 +');')
      }, [
        _createVNode(_component_process_tree_viewer, {
          ref: "process-tree-viewer",
          onSelectedNode: _ctx.nodeSelected
        }, null, 8, ["onSelectedNode"])
      ], 4)
    ]),
    _: 1
  }))
}